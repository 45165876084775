<!-- 搜索框 -->
<template>
  <div class="Search">
    <van-search
      clearable
      v-model="searchWord"
      :left-icon="require('@/assets/images/search.png')"
      :placeholder="placeholder"
      :show-action="showAction"
      @focus="focus"
      @input="input"
      @cancel="cancel"
    />
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    placeholder: { type: String },
    showAction: { type: Boolean, default: false }
  },
  data() {
    return {
      searchWord: ""
    };
  },
  methods: {
    focus() {
      this.$emit("focus");
    },
    cancel() {
      this.$emit("cancel");
    },
    input() {
      this.$emit("input", this.searchWord);
    }
  }
};
</script>
<style lang="scss">
.Search {
  .van-search {
    padding: 0;
    background: transparent;
    .van-search__content {
      background-image: linear-gradient(-90deg, #eceff8 0%, #fff7f7 100%);
      border-radius: 0.16rem;
      .van-field__body {
        width: 100%;
      }
      .van-cell {
        height: 0.32rem;
        .van-cell__value {
          display: flex;
          align-items: center;
          margin-left: 0.06rem;
        }
      }
    }
  }
}
</style>
