<!-- 品牌搜索页面 -->
<template>
  <div class="SearchResult">
    <Search
      :style="`top:${isShowDownLoad ? '.48rem' : '0'}`"
      placeholder="请输入品牌或车系"
      :showAction="true"
      @input="handleQuery"
      @cancel="cancel"
    />
    <div class="scrollWrap">
      <van-cell
        v-for="item in resultList"
        :key="item.serialId"
        :title="item.serialName"
        @click="nextPage(item)"
      />
    </div>
    <div class="noData" v-show="isNoData">
      <img src="@/assets/images/nodata.png" alt="" />
      <p>暂无内容</p>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import { searchSerial } from "@/api/api_home";
import { mapState } from "vuex";
export default {
  name: "SearchResult",
  data() {
    return {
      resultList: [],
      isRequest: false
    };
  },
  computed: {
    ...mapState(["isShowDownLoad"]),
    // 获取城市码值
    ciyInfo() {
      return this.$store.state.position;
    },
    // 是否暂无数据
    isNoData() {
      return this.resultList.length === 0 && this.isRequest;
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: "Home" });
    },
    nextPage(item) {
      this.$router.replace({
        name: "CarDetail",
        query: { serialId: item.serialId }
      });
    },
    // 搜索
    handleQuery(word) {
      this.isRequest = false;
      if (!word) {
        this.resultList = [];
        return;
      }
      searchSerial({
        searchSrtr: word,
        cityId: this.ciyInfo.cityId
      }).then(res => {
        if (res.code === 0) {
          this.resultList = res.data.info;
          this.isRequest = true;
        }
      });
    }
  },
  mounted() {
    // 搜索页面输入框自动聚焦
    const input = document.getElementsByClassName("van-field__control")[0];
    input.focus();
  },
  components: { Search }
};
</script>
<style lang="scss" scoped>
.SearchResult {
  .Search {
    padding: 0.1rem 0.1rem 0.2rem 0.18rem;
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 50;
  }
  .scrollWrap {
    padding-top: 0.62rem;
  }
  .noData {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    img {
      width: 1.3rem;
      height: 1.13rem;
    }
    p {
      color: #3a3d46;
      line-height: 0.22rem;
      margin-top: 0.16rem;
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
.SearchResult {
  .Search {
    .van-search__action {
      padding: 0 0.08rem 0 0.12rem;
    }
  }
}
</style>
